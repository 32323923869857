import logo from './logo.svg';
import dinnerIcon from './dinner-icon.svg';
import dessertIcon from './dessert-icon.svg';
import cocktailIcon from './cocktail-icon.svg';
import './App.css';
import React from 'react';
import AdComponent from './AdComponent';

const Config = {
  numDinnerRecipes: 200,
  numDessertRecipes: 101,
  numCocktailRecipes: 100,
  numRecipesUntilAd: 3,
  adsEnabled: true
};

const Category = {
  dinner: "dinner",
  dessert: "dessert",
  cocktail: "cocktail"
};

class App extends React.Component {

  apiBaseUrl = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? "http://192.168.1.139:3000" : "https://aichef.app";

  constructor(props) {
    super(props);
    this.state = {
      viewCounter: 1,
      category: Category.dinner,
      recipe: null
    };
  }
  
  componentDidMount = () => {
    const storedRecipeId = localStorage.getItem("dinnerRecipeId");
    if (storedRecipeId !== null) {
      this.fetchRecipe(JSON.parse(storedRecipeId));
    }
    else {
      this.nextRecipe(0);
    }
  };

  fetchRecipe = async (recipeId) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    const response = await fetch(`${this.apiBaseUrl}/data/${this.state.category}/r${recipeId}.json`, requestOptions);
    const recipeObj = await response.json();
    this.setState({ recipe: recipeObj });
    localStorage.setItem(this.state.category + "RecipeId", JSON.stringify(recipeId));
  };

  nextRecipe = (delta) => {
    const recipeId = localStorage.getItem(this.state.category + "RecipeId");
    const numRecipes = 
      (this.state.category === Category.dinner) ? Config.numDinnerRecipes : 
      (this.state.category === Category.dessert) ? Config.numDessertRecipes : Config.numCocktailRecipes;
    const nextRecipeId = recipeId !== null 
      ? (delta + JSON.parse(recipeId)) % numRecipes 
      : Math.floor(Math.random() * numRecipes);
    this.fetchRecipe(nextRecipeId);
    this.setState(prevState => ({ viewCounter: prevState.viewCounter + 1 }));
  };

  updateCategory = (newCategory) => {
    if (this.state.category !== newCategory) {
      this.setState({ category: newCategory }, () => this.nextRecipe(1));
    }
  };

  render = () => {
    return (
      <div className="App">
        <header className="App-header">
          <h2>
            <img src={logo} className="App-logo" alt="logo" />
            &nbsp;Ai Chef
          </h2>
          <span className="Tagline">
            Cook like a chef with AI-powered recipe ideas.
          </span>

          { this.state.recipe && this.renderRecipe() }
          
          <a
            className="App-link"
            href="https://www.devloop.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
              Created by devloop.org
          </a>
          <br />
        </header>
      </div>
    );
  };

  renderRecipe = () => {
    const selectCategory = (category) => category === this.state.category ? "CategorySelected" : "CategoryNotSelected";
    return (
      <>
        <span className="Categories">
          <a href="/" className={ selectCategory(Category.dinner) } onClick={ e => { e.preventDefault(); this.updateCategory(Category.dinner); } }>
            <img src={dinnerIcon} alt="dinner" /><br />
            Dinner
          </a>
          <a href="/" className={ selectCategory(Category.dessert) } onClick={ e => { e.preventDefault(); this.updateCategory(Category.dessert); } }>
            <img src={dessertIcon} alt="dessert" /><br />
            Dessert
          </a>
          <a href="/" className={ selectCategory(Category.cocktail) } onClick={ e => { e.preventDefault(); this.updateCategory(Category.cocktail); } }>
            <img src={cocktailIcon} alt="cocktail" /><br />
            Cocktail
          </a>
        </span>

        <p className="nav">
          <a href="/" className="previous" onClick={ e => { e.preventDefault(); this.nextRecipe(-1); } }>&laquo; Back</a>
          <a href="/" className="next" onClick={ e => { e.preventDefault(); this.nextRecipe(1); } }>Next &raquo;</a>
        </p>

        { this.renderAd() }

        <h3>{ this.state.recipe.name }</h3>

        <img src={"images/" + this.state.recipe.image} className="RecipeImg" alt="recipe"></img>

        <h4>Ingredients</h4>
        <ul>
          { this.state.recipe.ingredients.map((ingredient, idx) => <li key={idx}>{ ingredient }</li>) }
        </ul>
        <h4>Instructions</h4>
        <ol>
          { this.state.recipe.instructions.map((instruction, idx) => <li key={idx}>{ instruction }</li>) }
        </ol>
        
        <p className="nav">
          <a href="/" className="previous" onClick={ e => { e.preventDefault(); this.nextRecipe(-1); window.scrollTo(0, 0); } }>&laquo; Back</a>
          <a href="/" className="next" onClick={ e => { e.preventDefault(); this.nextRecipe(1); window.scrollTo(0, 0); } }>Next &raquo;</a>
        </p>

        <p className="Disclaimer">
          Disclaimer: All recipes and images provided by this service are completely generated by an Artificial Intelligence 
          and have not been tested or reviewed by any human. Use these recipes at your own risk and please exercise caution 
          when preparing and consuming food.
        </p>
      </>
    );
  };

  renderAd = () => {
    return Config.adsEnabled && (this.state.viewCounter % (Config.numRecipesUntilAd + 1) === 0) && (
      <span className="Ad">
        <AdComponent dataAdSlot="9500650147" />
      </span>
    );
  };

}

export default App;
