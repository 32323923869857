import React from 'react';

const AdComponent = (props) => {
  return (
    <>
    </>
  );
};

export default AdComponent;
